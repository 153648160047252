import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import ByteSizeLogo from "../../assets/bytesize-logo.png";
import RSSIcon from "../../assets/rss-icon.png";

function BlogsFooter() {
  const form = useRef();
  const rssLink = "https://blogs.bytesizelearning.org/rss.xml";

  const [show, toggleShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (!show) {
      form.current.reset();
    }
  }, [show]);

  const sendEmail = async (e) => {
    e.preventDefault();
    const email = form.current["member_email"].value; // Grab the email value using form ref
    if (form.current.checkValidity()) {
        try {
            // Send the email as JSON to the specified URL
            const response = await fetch('https://dev.bytesizelearning.org/subscribers/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Send the email using emailjs only after a successful POST request
            await emailjs.sendForm(
              "service_p10xb2d",
              "template_dsqsorb",
              form.current,
              "JoQcn25Gezs4At60T"
            );
    
            toggleShow(true);
        } catch (error) {
            console.error(error);
        }
    } else {
        form.current.reportValidity()
    }
};



  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(rssLink);
      setCopied(true);
      setIsClicked(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <footer
      id="subscribe"
      className="bg-gray-50 dark:bg-gray-800 static bottom-0">
      <div className="p-4 py-6 mx-auto max-w-screen-xl">
        <div className="justify-between items-center p-4 mx-auto mt-6 max-w-screen-md bg-gray-50 rounded-lg lg:mt-16 sm:flex dark:bg-gray-700 whitespace-nowrap">
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Sign up for our blog!
          </p>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex mt-4 ml-0 w-full sm:mt-0 sm:ml-5">
            <div className="relative w-full">
              <label
                htmlFor="email-subscribe"
                className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Email address
              </label>
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 hover:w-6 hover:h-6 duration-200 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
              </div>
              <input
                type="email"
                id="member_email"
                name="member_email"
                className="block p-3 pl-10 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-dodger focus:border-dodger dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-dodger dark:focus:border-dodger"
                placeholder="bytesize@learning.org"
                required
              />
            </div>
            <button
              type="submit"
              onClick={sendEmail}
              className={`ml-2 py-3 px-5 text-sm font-medium text-center border-2 border-dodger hover:bg-white hover:text-dodger text-white rounded-lg bg-dodger sm:w-fit duration-200 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                show ? "hidden" : ""
              }`}>
              {" "}
              Subscribe{" "}
            </button>
            <p
              className={`py-2.5 px-4 text-sm font-medium text-center hover:bg-white hover:text-dodger text-dodger rounded-lg bg-white sm:w-fit  focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                !show ? "hidden" : ""
              }`}>
              {" "}
              You have been subscribed! Thank you!{" "}
            </p>
          </form>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="text-center">
          <div className="flex justify-center items-center">
            <a href="/" className="flex justify-center items-center mb-4">
              <img
                src={ByteSizeLogo}
                className="mr-3 h-6 sm:h-9"
                alt="ByteSize Learning for kids and teens to learn tech skills Logo"
              />
              <span className="text-xl whitespace-nowrap dark:text-white hover:text-dodger hover:font-bold duration-200">
                ByteSize Learning
              </span>
            </a>
          </div>
          <div className="flex justify-center mt-2 space-x-5">
            <button
              onClick={copyToClipboard}
              className={`flex flex-row items-center px-4 py-2 text-sm font-medium 
              bg-transparent border rounded-lg transition duration-200 ease-in-out 
              ${
                isClicked
                  ? "text-green-400 border-green-400"
                  : "text-orange-500 border-orange-500"
              }
              hover:bg-white`}>
              {copied ? "Copied!" : "Copy RSS Link"}
              {!isClicked && (
                <img
                  src={RSSIcon}
                  className="ml-3 h-5 w-5"
                  alt="RSS icon"
                  onClick={copyToClipboard}
                />
              )}
            </button>
          </div>
    
          <ul className="flex justify-center mt-5 space-x-5">
            <li>
              <a
                href="https://www.facebook.com/ByteSizeLearningLLC"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-dodger duration-200">
                <svg
                  className="w-5 h-5 hover:w-6 hover:h-6 duration-200"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/bytesizelearning_/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-dodger duration-200">
                <svg
                  className="w-5 h-5 hover:w-6 hover:h-6 duration-200"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/LearnByteSize"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-dodger duration-200">
                <svg
                  className="w-5 h-5 hover:w-6 hover:h-6 duration-200"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.bytesizelearning.org"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-dodger duration-200">
                <svg
                  className="w-5 h-5 hover:w-6 hover:h-6 duration-200"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          </ul>
          <span className="block text-sm text-center text-gray-500 dark:text-gray-400 mt-4">
            © 2020-2025{" "}
            <a
              href="https://www.bytesizelearning.org"
              className="hover:underline hover:text-dodger duration-200">
              ByteSize Learning
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default BlogsFooter;
