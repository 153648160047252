import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";

import AGIBeforeAndAfter from "../../assets/JUNE_30_2023Assets/AGIBeforeAndAfter.png";
import SoYesterday from "../../assets/JUNE_30_2023Assets/SoYesterday.png";
import DrakeXAIOverAGI from "../../assets/JUNE_30_2023Assets/DrakeXAIOverAGI.png";
import MLMeme from "../../assets/JUNE_30_2023Assets/MLMeme.png";

import XAIOverAGIThumbnail from "../../assets/JUNE_30_2023Assets/XAIOverAGIThumbnail.png";

function TEMPLATE() {
  const blogTitle = "Why XAI > AGI: What That Means and Why That Matters";
  const blogSubtitle = "You've heard of AGI, probably, but what about its forgotten cousin, XAI? In this blog post, we delve into the intricacies of XAI and shed light on its potential significance in our AI-imbued future. Intrigued? Read On";
  const blogAuthor = "Andrew Rust";
  const datePublished = "June 30th, 2023";
  const shareLink = "http://blogs.bytesizelearning.org/posts/xai-should-be-higher-focus-than-agi-in-2023";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="Artificial Intelligence, Coding, Technology" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
        <meta property="og:image" content={XAIOverAGIThumbnail} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
            <em className="text-xl">What am I talking about? AI of course.</em>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={SoYesterday}
            alt="So Yesterday Meme about AGI"
          />
          <p class="mb-4">
            Here we go again, another opinion piece on AI. If you already know
            what AGI and XAI mean, then feel free to skip ahead to the third
            section. If you don't here is the jist::
          </p>
          <p class="mb-4">
            XAI - Explainable Artificial Intelligence: Are you able to make
            sense of why it did what it did? Think of it as ELI5 (Explain Like
            I'm 5) ' except instead of a 5 year old, it just needs to make
            {" "}<strong>complete</strong>{" "} sense to the top computer scientists in the world. Easier said than done...
          </p>
          <p class="mb-4">
            AGI Artificial General Intelligence: Think terminator but 50/50 on
            being evil or benevolent, take over the world type of thing, bigger,
            faster, better, stronger. We're talking about machines that can
            understand, learn, and apply knowledge across a wide range of tasks.
            Who knows what could happen here.
          </p>
          <p class="mb-4">
            <em>
              As a quick aside, the{" "}
              <a href="https://www.youtube.com/watch?v=z6atNBhItBs">
                'Alignment Problem'
              </a>{" "}
              summarizes many of the concerns some have for AGI
            </em>
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={AGIBeforeAndAfter}
            alt="Before and After AGI Meme"
          />
          <p class="mb-4">
            In my view, 'progress' looks like AGI, but 'improvement' looks like
            XAI ' I'll get to the argument behind that later, but just to set
            the context once more through this silly example:
          </p>
          <h2>A Silly, Yet Simpified Example</h2>
          <p class="mb-4">
            You're back in math class (Geometry or Calculus-level). The teacher
            asks everyone to split off into pairs to work on an assignment
            together. There is a test coming up in a week or so and you've
            barely been paying attention so this might be a good excuse to get
            some solid reps in with the class material so you are okay for the
            test. Long story short, there are two people left in class that you
            can pair with:
          </p>
          <p class="mb-4">
            Ada: Well' Ada isn't exactly your ideal group partner for learning,
            but you surely wouldn't have to do much in her group. She means well
            but is so far ahead in the class material that it wouldn't be worth
            you even looking at the assignment, just let her do it. Talking to
            her doesn't really make any sense either, she speaks in weird haikus
            and last time you tried to ask her was slope was, she calmly replied
            that is was 'the limit of the difference quotient as the interval
            tends to zero' ' probably correct but doesn't mean anything to a
            layman.
          </p>
          <p class="mb-4">
            Xavier: He's nice, but just a bit slow and has to speak aloud (very
            loudly) each and every single thought he is thinking at all times.
            He has 100's of pages of notes but moves at a snail's pace when it
            comes to actually churning stuff out. You'd probably be better off
            actually learning the material working with him instead of Ada,
            although doing nothing does sound appealing.
          </p>
          <p class="mb-4">
            Well if that hypothetical isn't obvious enough, Ada represents AGI
            and Xavier represents XAI, one will ensure you don't pass the test,
            and the other is like eating vegetables: you know it's good for you
            but you hate it.
          </p>
          <h2 class="mb-4">Great, So Why Should I Care About Either of These?</h2>
          <p class="mb-4">
            For one, like it or not, this is where the world is headed so best
            know at least enough to hedge your bets.
          </p>
          <p class="mb-4">
            Second, the implication of the worst-case scenario for AGI is too
            significant to ignore ' at least conceptually. Without a proper
            understanding ('risk analysis') of AGIs decision-making processes
            (which is where XAI comes in), we could easily find ourselves
            listening to a 'AI Teacher' unsure of what and why we are doing what
            it says, just another day in the life in the Big Tech dystopia.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={MLMeme}
            alt="ML Meme About Machine Learning"
          />
          <h2>Closing Pandora's box, and Opening the Black One</h2>
          <p class="mb-4">
            <em>
              I'm kidding ' we obviously can't close Pandora's box, but maybe
              duck taping the lid would be a start.
            </em>
          </p>
          <p class="mb-4">
            Up to this point, the core of all technology have one thing in
            common: we are able to exert full control. At nearly every level of
            hardware and software we can describe in excruciating detail how an
            electric vehicle, or giant cookie dough mixer works. But AGI
            presents a new challenge: unpredictability.
          </p>
          <p class="mb-4">
            Now what if I told you that the computer scientists that developed
            the most popular LLM, (Large Language model) ChatGPT, could not
            definitively tell you why the prompt you gave ChatGPT generated the
            specific result that you got. Sure, there are plenty of details they
            could share to keep you listening for hours but at a certain point
            the weights in the Transformer (which is the 'T' in ChatGPT, which,
            architecturally, are recurrent neural networks) become too nebulous
            and of too high in volume to render adequate explanation, even to
            the programmers themselves. In other words ' it is a black box.
          </p>
          <p class="mb-4">
            LLMs aren't the first non-deterministic ('black box': same input =
            different outputs) technologies we've used. For years, stochastic,
            genetic, and heuristic-based algorithms and simulations have been
            used in a bunch of research and public settings, but their use was
            generally confined to controlled environments, and the stakes were
            low. Worst case was that researchers got weird results that they
            weren't able to explain and probably just archived them.
          </p>
          <p class="mb-4">
            Black boxes aside, the cornerstone of science is the reproduction of
            an observed phenomenon. The inability to do so is a worry cause for
            many when it comes to understanding technological systems like LLMs.
            Despite its power, the non-deterministic nature of these models
            gives rise to valid apprehensions in the scientific community and
            beyond.
          </p>
          <h2>So What Exactly Does XAI Promise?</h2>
          <p class="mb-4">
            Well for starters, it allows for much easier education and
            low-stakes use of AI models, XAI models can more safely be used in
            public settings since the range of possible outputs are feasibly
            understood and repeatable. This means that kids, grandparents,
            anyone could safely use XAI models! Of course, if the model itself
            were designed with safety in mind, it is entirely possible that a
            model could be designed with the opposite in mind. Either way the
            potential for safe use would be possible. However it is worth noting
            that these models are probably not nearly as fun to use because they
            would be noticeably weaker that the cutting-edge models.
          </p>
          <p class="mb-4">
            The vision held by many{" "}
            <a href="https://time.com/6289953/schumer-ai-regulation-explainability/">
              lawmakers
            </a>{" "}
            and educators could also come to light - an AI that is not only safe
            but also comprehensible for the average user. In essence, this is
            the promise of XAI.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={DrakeXAIOverAGI}
            alt="Drake Meme: XAI over AGI"
          />
            <h2>Conclusion: Raw Power or Control?</h2>
          <p class="mb-4">
            While the development of AGI might be exciting and offer benefits
            beyond our wildest dreams. This 'progress' only has the potential to
            offer incredible benefit to society, certainly no guarantee there.
            Progress as a standalone objective ignores all potential
            externalities of such progress. In contrast, improvements in XAI
            offer broad, nearly certain benefits that could hold an equal, if
            not greater, importance for our collective attention towards AI's
            impact on humanity. My argument is not to stall progress on AGI, but
            rather to emphasize that other paths of progress, like XAI, can lead
            to "improvements" and not just the advancement of technology for its
            own sake.
          </p>
          <p class="mb-4">
            We stand on the edge' of several things probably but for one, on the
            precipice of the AI-driven era, progress balanced with
            understanding. AGI offers the former, while XAI provides the latter.
            After all, what's the point of progress if we can't comprehend it?
          </p>
          <p class="mb-4">
            If you found this blog interesting feel free to connect with me on{" "}
            <a href="https://www.linkedin.com/in/andrew-rust30/">LinkedIn</a>{" "}
            and read my other blogs on{" "}
            <a href="https://blogs.bytesizelearning.org/">ByteSize Blogs</a>.
          </p>
        </body>
      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default TEMPLATE;
