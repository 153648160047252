import React from "react";
import BlogCTACard from "./UniversalBlogComponents/BlogCTACard";

function BlogsHome() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
          <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            ByteSize Blogs
          </h2>
          <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Thinking hard about the future of education, technology, and society
          </p>
        </div>
        <div className="grid gap-8 lg:grid-cols-2">
          <BlogCTACard
            blogTitle={"Education, AI, and Astronaut Philosophers?"}
            blogSubtitle={
              "So you're wondering what the world looks like after AI has taken over, huh? Let's take a look at the future of AI and education from a March 2023 lens."
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"March 24th, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/ai-and-the-future-of-education"
            }
            blogTags={["Higher Education", "Artificial Intelligence"]}
          />
          <BlogCTACard
            blogTitle={"What is the point of Education?"}
            blogSubtitle={
              "Why did education exist in the first place? What is the point of it anyway? Let's dig into the functions of education and see what we can find."
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"April 7th, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/what-is-the-purpose-of-education-in-2023"
            }
            blogTags={["K-12 Education", "STEM Education", "Creativity"]}
          />
          <BlogCTACard
            blogTitle={
              "Creativity Unraveled: Harnessing the Power of the Spontaneous"
            }
            blogSubtitle={
              "Creativity is a powerful tool that can be used to solve problems, but how do we harness it in our lives and in education? And what does that even mean!"
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"May 5th, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/creativity-unraveled-and-the-power-of-the-spontaneous"
            }
            blogTags={["STEM Education", "Creativity"]}
          />
          <BlogCTACard
            blogTitle={
              "Cyber Street Smarts: A Proposition for a Driver's License 2.0"
            }
            blogSubtitle={
              "Do we need an 'Internet License' for the digital highways and alleyways? - Delve into the discussion around digital literacy, its necessity in our ever-expanding cyber world, and the idea of an Internet License as a safeguard and empowerment tool for users."
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"June 2nd, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/exploring-a-digital-literacy-license-in-2023"
            }
            blogTags={[
              "Artificial Intelligence",
              "Technology",
              "Cybersecurity",
            ]}
          />
          <BlogCTACard
            blogTitle={"Why XAI > AGI: What That Means and Why That Matters"}
            blogSubtitle={"You've heard of AGI, probably, but what about its forgotten cousin, XAI? In this blog post, we delve into the intricacies of XAI and shed light on its potential significance in our AI-imbued future. Intrigued? Read On"}
            blogAuthor={"Andrew Rust"}
            datePublished={"June 30th, 2023"}
            shareLink={"http://blogs.bytesizelearning.org/posts/xai-should-be-higher-focus-than-agi-in-2023"}
            blogTags={["Artificial Intelligence", "Technology", "Coding"]}
          />
          <BlogCTACard
            blogTitle={
              "A Problematic Act of Abstraction"
            }
            blogSubtitle={
              "Technology literacy? It doesn't even matter what that means, you need it. Why digital literacy now is no longer a nerdy hobby or a niche skill, but a necessity for all."
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"August 11th, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/the-problem-of-abstracting-technical-knowledge"
            }
            blogTags={["Technology", "Coding", "STEM Education"]}
          />
          <BlogCTACard
            blogTitle={
              "Socrates to Silicon: 5 Overlaps of Classical Disciplines With Modern Innovation"
            }
            blogSubtitle={
              "While many, including myself, increasingly question the relevance of traditional education, it's crucial to acknowledge the undeniable influence of classical disciplines on today's technological fields"
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"September 1st, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/old-school-vs-new-school-education-topics"
            }
            blogTags={["Artificial Intelligence", "Technology", "STEM Education"]}
          />
            <BlogCTACard
            blogTitle={
              "Computing, Creating, and Curiosity: The Triad of Technology"
            }
            blogSubtitle={
              "The foundation of modern creation, just doing it, and the heartbeat of the socratic method - here we attempt to conceptualize the broader issue and pose a solution regarding technology education."
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"October 6th, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/triad-of-technology-education"
            }
            blogTags={["K-12 Education", "STEM Education", "Creativity", "Technology"]}
          />
          <BlogCTACard
            blogTitle={
              "Education and Gamification: Where Do The Games End and Learning Begins?"
            }
            blogSubtitle={
              "Diving deep into the buzzword of gamification and its implications on education - good and bad."
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"November 7th, 2023"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/gamified-learning-and-its-consequences"
            }
            blogTags={["STEM Education", "Technology", "Gaming"]}
          />
            <BlogCTACard
            blogTitle={
              "The Critical Role of FLOSS in Educational Technology: Fostering Innovation, Security, and Access"
            }
            blogSubtitle={
              "What is FLOSS Software and what does it have to do with education? As it turns out, quite a lot actually"
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"February 15th, 2024"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/floss-in-technology-education"
            }
            blogTags={["Technology", "STEM Education"]}
          />
            <BlogCTACard
            blogTitle={
              "Techno-Philosophy on Human-Computer Relations"
            }
            blogSubtitle={
              "What is our philosophy on adopting new technologies? Do we have one?"
            }
            blogAuthor={"Andrew Rust"}
            datePublished={"May 27th, 2024"}
            shareLink={
              "https://blogs.bytesizelearning.org/posts/techno-philosophy"
            }
            blogTags={["Technology", "Artificial Intelligence", "Philosophy"]}
          />
          {/* <BlogCTACard
            blogTitle={
              ""
            }
            blogSubtitle={
              ""
            }
            blogAuthor={" Rust"}
            datePublished={", 2023"}
            shareLink={
              ""
            }
            blogTags={["n", ""]}
          /> */}
        </div>
      </div>
    </section>
  );
}

export default BlogsHome;
