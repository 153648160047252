import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";

function TEMPLATE() {
  const blogTitle =
    "Education and Gamification: Where Do The Games End and Learning Begins?";
  const blogSubtitle =
    "Diving deep into the buzzword of gamification and its implications on education - good and bad.";
  const blogAuthor = "Andrew Rust";
  const datePublished = "November 7th, 2023";
  const shareLink =
    "https://blogs.bytesizelearning.org/posts/gamified-learning-and-its-consequences";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="STEM Education, Creativity" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <p className="mb-4">
            <em>
              Preface/FYI: I consciously omit statistics and studies from my
              articles, not due to laziness or a disdain for science, but
              because I find they often serve as diversions from the greater
              focus of the writing. In my view, including statistics or 'popcorn
              facts', as I like to call them, ignore the necessary context
              behind the result(s) of a study or is subject to unfair scrutiny
              if it contradicts our preconceptions where we could question the
              methodology and potential biases behind it. It also never seems to
              come up that the facts and figures NOT included in a piece of work
              but exist are just as important as what IS included. In the end,
              these all serve to detract from what is otherwise an exercise in
              pure reasoning and philosophical discourse, not Google-Fu of who
              can find the most studies on a topic. On the more extreme end of
              this opinion, facts and numbers stripped from studies are easy to
              hide behind and use as decoys for what are otherwise difficult
              intellectual topics to think about. To make it all simpler, I
              choose to not include them entirely, even when they might
              'strengthen' a statement of mine. I am sure that figures exist
              surrounding this space and I encourage you to seek them out if
              interested, otherwise you can focus on the raw reasoning along
              with me. If you find a blatant factual error, feel free to share.
              But we seek to reason as Descartes would, even if objective truth
              itself is a subjective construct. Thank you for your
              understanding.
            </em>
          </p>
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
          <p className="mb-4">
            <strong>Is Gamified Learning All Fun and Games?</strong>
          </p>
          <p className="mb-4">
            Gamification—it's a buzzword that's been ricocheting through
            education and advertising alike with the ferocity of a pinball
            (Among many other playful gaming analogies I could've chosen). It
            seems to be the standout feature of many cutting-edge learning apps
            (LMS - Learning Management Systems), websites, and educational tools
            - boasting unparalleled engagement through games that could be the
            silver bullet to the woes of declining efficacy in traditional
            educational methods amongst the youths. While this notion is
            convincing and exciting, combining the engagement potential of
            gaming with the utility of education is both a tall order and one
            that would have many unintended consequences. I remain skeptical of
            an overtly 'gamified' approach to learning due to concerns around
            longevity, depth(See related:{" "}
            <a href="https://blogs.bytesizelearning.org/posts/the-problem-of-abstracting-technical-knowledge">
              abstraction
            </a>
            ), and inclusion - among others.
          </p>
          <p className="mb-4">
            But first, let's entertain the promising components of gamified
            learning:
          </p>
          <h3>First, the Good - Ignoring Bad and Ugly</h3>
          <p className="mb-4">
            <em>An Encouraging Paradigm For Engagement</em>
          </p>
          <p className="mb-4">
            Given kids are increasingly unfamiliar and unhappy with pen & paper
            and have technology all but soldered to their fingertips,
            "game-ifying" education holds undeniable charm as an engagement tool
            in the modern technology landscape - perhaps even a trivial
            extension. For brevity's sake, here are some high-level benefits
            that gamification promises and delivers on some levels:
          </p>
          <ul>
            <li className="mb-2">
              <strong>Engagement:</strong> Kids love games, so making things
              feel more like games they are familiar with has magnetic qualities
              that attract otherwise disinterested kids into some education
              sphere, even one neutered due to gamification.
            </li>
            <li className="mb-2">
              <strong>Scalability:</strong> Modern technology makes any
              technology-based solution functionally infinitely scalable, making
              it just as easy to serve one child as it might be to serve 100 and
              barely any more work to serve 1 million. Traditional methods
              cannot and will never match this scale.
            </li>
            <li className="mb-2">
              <strong>Personalization:</strong> Similar to scalability, games
              can offer personalized solutions on a scale and with granularity
              that is hard to achieve otherwise. So it get points here too.{" "}
            </li>
            <li className="mb-2">
              <strong>Common Ground:</strong> Educators who are even a few years
              removed from the generation they are teaching may find it
              increasingly difficult to relate, especially as the gap widens -
              games can transcend these generational barriers and make learning
              seem more peer-to-peer that teacher-student (server-client).
            </li>
            <li className="mb-2">
              <strong>Collaboration and Competition:</strong> A common enemy
              brings together a group of people like no other - collaborative
              (same team) and competitive (opposing teams) implementations are a
              hallmark of any popular game(sports too) and brings out energy and
              passion that can get the learning juices flowing no doubt.{" "}
            </li>
          </ul>
          <p className="mb-4">
            These seem great if left alone and this would be the end of the
            sales pitch for any modern, gamified educational product, but it's
            naive to think the discussion should end here or that no negative
            consequences could outweigh these benefits. Now for the other side
            of the (proverbial) coin:
          </p>
          <h3>The Bad & Ugly: Playing With Addiction Centers</h3>
          <p className="mb-4">
            <em>Who's Winning? Wait, Why Are We Keeping Score?</em>
          </p>
          <p className="mb-4">
            Dopamine-hacking, incentive-based learning, positive reinforcement -
            whatever you decide to call it - categorically function on top of
            the same useful, but limited principle. It is the act of
            incentivizing certain behavior through an external reward or stimuli
            of some kind, generally used when there is no intrinsic or otherwise
            existent motivation to do something - so motivation is manufactured.
            This is pervasive in nearly all aspects of life, but video games in
            particular employ this strategy
            <br />
            heavily to maximize the fun of the game and subsequently, the time
            spent playing. In educational spheres this can be especially
            problematic as making the end-goal of education a finite, or
            crumb-following based game detracts from the long-term purpose of a
            what is a 'perpetual' game - a life of learning. Sugar-coating
            hard-to-swallow pills might make it easier for you to choke on in
            the same way that games that rely on incentives stop working when
            incentives lose their rush. Kids pick up and put down new and old
            games all the time and learning (the meta-conceptual 'learning')
            shouldn't be something that gets stale every few weeks and halts.
            Long-term intrinsic motivation behind learning should be the goal
            and 'dopamine-hacking' in its very nature is a short-term game. It
            might be good at getting the ball rolling, but the hard part is to{" "}
            <em>keep</em> it rolling, not letting it stop. So dependence on
            fresh, fun and externally motivating stimuli is not a viable
            long-term solution - it resembles more of a casino's
            logic(addiction) than what should be in a schoolhouse. Attention
            isn't a zero-sum game per se but pretending that is effectively the
            opposite is a dangerous game to play - given a child's educational
            future is at stake.
          </p>
          <p className="mb-4">
            Another concern around gamified learning is the depth. To first
            frame this in our past, history's most profound thinkers didn't fuel
            their intellects through fun - often the contrary. They grappled
            with complex problems, often in a maelstrom of frustration, strive,
            and iteration to reach new depths of knowledge for humankind at
            their own expense. Even in modern-day, deeply knowledgeable people
            can attest that much of their learning is not fun - one could even
            make a reasonable argument that oftentimes the extent of knowledge
            learned is strongly related to the amount of time and frustration
            invested into figuring out a difficult, specific problem. This is a
            mechanism easily dodged by abstracting concepts into a game. It's
            foolish to discount all of gamified learning under this umbrella but
            serious consideration of what depth is sacrificed by abstracting
            educational topics into games should not be a secondary focus.
            Education should seek to create lifelong learners, an 'infinite
            game' that is more aligned with a marathon, void of winners and
            losers, and where most miles are traversed alone, where internal
            motivation might be all you have. We're dabbling with delicate
            mechanisms here: intrinsic motivation, the risk of addiction, a need
            for grit, and genuine interest in topics themselves - not games.{" "}
          </p>
          <p className="mb-4">
            A double-edged sword of gamified learning too is that of
            competition. Generally, competitive games with clear winners and
            losers tend to favor particular types of students and require
            assessment or comparison of some kind. While this is useful, it can
            also be weaponized against the ends of learning itself and
            detrimental to students who perform poorly under traditional modes
            of motivation (positive/negative reinforcement). In many topics too,
            quantification in and of itself is pointless. Not only might it
            indicate that learning is a strictly linear process (as you 'level
            up' or obtain 'points/wins') but then how does one compare a level 9
            history learner with 600 tokens worth of math knowledge? Can one
            'speedrun' education in the same way you can in level-based games?
            The pursuit of knowledge as a conceptual idea that is the underlying
            goal of education remains a non-linear, largely non-quantifiable
            process with no endgame. So this can easily send the wrong message
            to <em>both</em> successful and unsuccessful students as they get
            the wrong idea behind the larger context of what an education is
            supposed to be when the games are over.
          </p>
          <p className="mb-4">
            Lastly, a concern that deserves an article of its own and is as
            pervasive as ever is the faculty of data privacy and subsequent
            misuse. Gamified systems that personalize education by collecting
            and analyzing data fester a plethora of concerns related to security
            of this data, unintended consequences (getting kids addicted because
            an algorithm was optimized for engagement that lacks nuance), and
            24/7 access to camera feeds, microphones, keystrokes, etc that
            technology inherently allows for. This is obviously company and
            product-specific but it's a valid concern as we should remember what
            products and business must do at their core and question what it is
            that they might be willing to jeopardize to keep you buying
            products. What amount of education justifies a price and - Are
            companies harvesting data about students solely in the name of
            education, or does money slip in too? Technology lacks nuance, we
            designed it that way on purpose to account for the infallibility of
            humans, but this also removes much needed nuance that can get out of
            hand if not monitored _and_ understood properly, especially if we
            consider how vulnerable kids are to these technologies and their
            consequences.{" "}
          </p>
          <h3>In Search of Much Needed Balance</h3>
          <p className="mb-4">
            <em>Ethical Engagement - Where do we err?</em>
          </p>
          <p className="mb-4">
            As usual, no answers to truly difficult questions come in a blog,
            but it's emblematic of learning. No such hard lessons are learned
            with a video or a game(or on Facebook, X, etc). They are learned in
            the real world. A gamified proxy can never replace the profound
            difficulty and effort required to fully educate oneself.
          </p>
          <p className="mb-4">
            Gamified learning is not the panacea for educational woes, but
            rather a tool among many. It's about striking a balance, blending
            the engaging power of games with the raw, sometimes bitter taste of
            traditional learning. It alluring given how detached many kids are
            now these days, games seem like the answer - but we need to think
            long term. Games end, learning should not. We should continue to
            ask: what exactly are we incentivizing with this game? The 'endgame'
            if you'll entertain me, is the empowerment of a self-driven
            learning, independent of game platforms and even educators too.
            Education should be about sustainability, creating life-long
            learners who know you can't "speedrun" true understanding.
          </p>
          <p className="mb-4">
            As educators and learners, we must seek not just to engage but to
            enlighten; not just to play, but to ponder; not just to score
            points, but to spark potential. It's a game, yes, but the stakes are
            real, and the outcome matters. Let's play it wisely. If you enjoyed
            reading, consider following along with future posts via{" "}
            <a href="https://blogs.bytesizelearning.org/rss.xml">RSS Feed</a>,
            or connect with me over{" "}
            <a href="mailto:andrew@bytesizelearning.org">email</a> or{" "}
            <a href="https://www.linkedin.com/in/andrew-rust30/">LinkedIn</a> -
            always happy to chat about technology & education. Thanks for
            reading.
          </p>
        </body>
      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default TEMPLATE;
