import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";

function TEMPLATE() {
  const blogTitle = "Techno-Philosophy on Human-Screen Relations";
  const blogSubtitle = "What is our philosophy on adopting technologies? Do we even have one?";
  const blogAuthor = "Andrew Rust";
  const datePublished = "May 27th, 2024";
  const shareLink = "https://blogs.bytesizelearning.org/posts/techno-philosophy/";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="Artificial Intelligence, Technology, Philosophy" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
<p className="mb-4"><em>"The more things change, the more they stay the same"</em></p>
<p className="mb-4">That may be true of humanity and our virtues and vices, but certainly seems less poignant if we speak about technological progress. Specifically related to our relationship with "screens"; the devices that we <em>interface</em> with - ironically more than <em>faces</em> themselves. </p>
<p className="mb-4">As humanity's emphasis into the humanities dwindles into the background of the zeitgeist while STEM reigns supreme, I posit that technology's integration with our lives <em>should</em> only be as relelvant as a thoughtful 'humanistic' philosphy on it allows it to be. Living and breathing a life of technology <em>needs</em> a philosophy - because without one there is too much to lose. To avoid catastrophies of any mix of malice or ignorance, intentional questions must be put forth.</p>
<p className="mb-4">"<em>What does a healthy relationship with screens look like?</em>"</p>
<p className="mb-4">A quick answer might be something like a threshold of time, or another simple measurement that is flexible as screens become a more ubiquitous interface than faces themselves. </p>
<p className="mb-4">How About: "<em>What does an <strong>unhealthy</strong> relationship with technology look like?</em>"</p>
<p className="mb-4">This is still non-trivial, but perhaps I can pose three useful criteria under which we can decide individually (and collectively) what is acceptable and not. I risk sounding Luddite-like in being contrarian to our current cultural love affair but skeptical analysis <strong>should</strong> be welcome in tech-adored scientific communities, even it is unfounded skepticism. First though, I will dissect our current relations a bit more. </p>
<h3>Metaphors for Modern Relationships</h3>
<p className="mb-4">Despite our claims, we harbor deep emotional attachment to our devices in the first world. Here are some analogies, though flawed, that are often used:</p>
<ul>
<li className="mb-2">
<p><strong>Peer</strong>: From a friend to a therapist, devices are seen as extensions of ourselves, understanding our preferences and anticipating needs. This emotional bond can make devices feel like both our best friend and worst enemy.</p>
</li>
<li className="mb-2">
<p><strong>Tool</strong>: Devices are often seen as inert objects, existing only to aid self-actualization, materialism, or time management. They solve historically non-trivial problems trivially, like locating the nearest gas station while out of town.</p>
</li>
<li className="mb-2">
<p><strong>Personal Assistant</strong>: There's an unequal power balance here. Notifications demand attention like a pet, but you control the device. It's subservient, demanding at times, but overall positive.</p>
</li>
</ul>
<p className="mb-4">Other metaphors may exist, such as a human feat worthy of worship or an idol of progress. But these analogies, while clean, oversimplify the complexity of modern technology and its future advancements. Comparing a hammer to a software technology like YouTube, which consumes most of our field-of-vision and attention for hours on end, seems unreasonable. The dynamic between an 8-year-old and a chatbot containing the collective intelligence of billions raises questions about who is truly the "pet." Can a relationship with an emotionless "peer" providing guidance to a complex emotional human be equitable or safe? These questions, fair or not, are valid and warrant consideration.</p>
<h3>Control: Who holds the keys?</h3>
<p className="mb-4">One prevailing question from the above is: Who truly holds control in these relationships? With humans remaining largely unchanged (from a physiological perspective), while technology advances rapidly, it's fair to question where the balance of power is, and if it is soon to be unequal.</p>
<p className="mb-4">Human connections have undeniably shaped society and individuals, but our understanding of human-computer relationships is still limited. We control our devices, or so we believe. Yet, every tap, swipe, and click seemingly <em>under our control</em> is subtly (or not, in some cases) influenced by algorithms. At their benign best, they might simply reccomend shows to watch, what to eat, how to get from A to B, but more sinisterly can dictate who we meet, where we work, and through only a thin veneer of self-awareness; what we think and do. Where is the line between functionality and influence? Are we paying attention? </p>
<p className="mb-4">So, who sets the terms of engagement? Users, developers, or algorithms? The ethical dimension of technology's impact on our autonomy is not only a technological question of control, but a query to our human judgment - to what extent do we value our autonomy? Even if that autonomy is flawed. It's naive to assume that we should relinquish control to flawed technological systems just because humans are inherently flawed, because so too are the systems we design(Isaac Asimov's work, while dated, is interesting fodder for further deliberation). The difference is, human motives, while flawed are comprehensible to other humans: greed, lust, envy. The motivations of Machine Learning are tautologially incomprehensible to humans. And in adopting these technologies, the designers wield significant but perhaps <em>unknown</em> and <em>uncontrollable</em> power, following moreso are the corporations that deploy them.</p>
<p className="mb-4">We champion technological innovation as it has freed many from physical drugery, but left them in what better place? It’s a delicate dance of progress and precaution, where the lines between aiding and automating can blur, potentially leading to a loss of meaningful human control. The role of humans in this equation must be assertive—ensuring that they, not the systems they create, hold the keys to their digital and physical realms. We champion this cause at ByteSize by promoting and teaching the use of libre software, which embodies principles of user control, transparency, and ethical use, ensuring that while our tools are advanced and helpful, they remain under our command, serving our needs without undermining our values or autonomy. All the more reason to embrace philosophy when it comes to technology, not evade it. </p>
<h3>Supplement Reality, But Maybe Don't Replace It</h3>
<p className="mb-4">A favorite parable of mine, Chesterton's fence reminds of the value of existing structures before replacing them. In this context, the true, holistic value of face-to-face interactions should not be dismissed in a quick motion toward Zoom calls or VR. These digital mediums provide a neat supplement in cases where in-person interactions are not feasible but surely don't replace non-verbal cues of expression or any deeper social comfort. It's dually important too to consider where replacement occurs and might be acceptable. For many, working remotely and engaging virutually sacrifices very little, but if considered in the context of dating or time spent with freinds and family, there is obviously much more lost.</p>
<p className="mb-4">While it's easy to dismiss the youth's newfangled toys as elders have done for millennia, we must consider if humans can handle the rate of geometric change that technology brings. Biologically, humans evolve at a slower, perhaps linear pace at best, and our current forms may not be as adequate in adapting to technology as we think. It's wise to be skeptical of immersing ourselves, especially children, in virtual realities without understanding the long-term effects on their development and well-being. What is good for business, government or an individual very well may not be good as a new paradigm, so caution in adopting is the main advocacy here. </p>
<h3>Promote an Active, Not Passive Attitude</h3>
<p className="mb-4">The meteoric rise of mobile computing is not without its issues, despite its impressive capabilities. My primary concern lies in its tendency to foster passivity among users, promoting a zombie-like trance as the ideal user experience, which fundamentally contradicts human flourishing. I propose that <strong>production is a much more powerful mode than consumption</strong> - a seemingly radical idea in an age defined by consumerism.</p>
<p className="mb-4">The term 'engagement' has been diluted to include passive actions such as retweeting, liking, and watch time. True engagement, however, should involve more human action: writing, thinking, composing, etc. An artistic rendering inspired by a favorite book or movie, a 2D video game produced, or a coding script made is 'engaging' with technological media. Not re-tweeting, listening to never-ending podcasts, or even watching a movie intently. These actions might be engagement in the literal sense, but they represent <em>passive</em> engagement. Ironically, despite the new abilities offered by technology, few bother to learn, use, and eventually create anything. This ranges from written and spoken content to video and art. This lack of active participation is problematic at best and existentially concerning at worst.</p>
<p className="mb-4">We have always emphasized the importance of creation and the power that making things brings, regardless of quality. We value children's crayon drawings because they represent potential and bring something new into the world. Passive consumption, on the other hand, increases perfectionism and restrains our attempts at creation, as they may fall short of the skewed threshold of normal or average (e.g., body dysmorphia, perfect lives). Technology empowers us only if we use it actively, preventing the consolidation of power into fewer hands. Creating things, regardless of quality, democratizes technology as intended.</p>
<h3>Toward a Potentially Tumultuous Future: Well, Now what?</h3>
<p className="mb-4">Awareness is a step in the process, but it's unclear where it leads. Asking questions, even if they seem foolish, is crucial in navigating the complexities of technology. Blindly following mainstream advice can be problematic, as not all technology warrants adoption, especially if you doubt its utility or benefit in your life.</p>
<p className="mb-4">Consider a more holistic approach to technology. The smart fridge might be a funny gimmick, but are there greater implications? What about security, maintenance, and privacy? What happens to 'dumb' tech that gets the job done? Our decisions regarding technology have social, economic, and psychological well-being implications, and we should make them wisely.</p>
<p className="mb-4">Reflect on whether technology will empower or atrophy you. Does it make you more or less independent? Whose device is this, and who controls it? What does it make you do, feel, or think? I won't explicitly tell you what to do, but I encourage you to ask questions, uncomfortable as they may be, because they are necessary. Technology, for whichever metaphor you choose, should be <strong>for</strong> human flourishing, not a shiny new source of subservience and alienation. The nuances of the differences, however are increasingly difficult to spot and address. </p>
<p className="mb-4">If you enjoyed reading and would like to connect, feel free to email me or connect with me on LinkedIn.</p>
</body>

      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default TEMPLATE;
