import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";

// import SocratesMeme from "../../assets/APRIL_7_2023Assets/SocratesMeme.jpg";

import CreativityMeme from "../../assets/MAY_5_2023Assets/CreativityMeme.png";
import ModernArtMeme from "../../assets/MAY_5_2023Assets/ModernArtMeme.png";
import SchoolCreativityMeme from "../../assets/MAY_5_2023Assets/SchoolCreativityMeme.png";
import ShorseImage from "../../assets/MAY_5_2023Assets/ShorseImage.png";

import CreativityBlogThumbnail from "../../assets/MAY_5_2023Assets/CreativityBlogThumbnail.png";

function MAY_5_2023() {
  const blogTitle = "Creativity Unraveled: Harnessing the Power of the Spontaneous";
  const blogSubtitle = "Creativity is a powerful tool that can be used to solve problems, but how do we harness it in our lives and in education? And what does that even mean?";
  const blogAuthor = "Andrew Rust";
  const datePublished = "May 5th, 2023";
  const shareLink = "https://blogs.bytesizelearning.org/posts/creativity-unraveled-and-the-power-of-the-spontaneous";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="STEM Education, Creativity" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
        <meta property="og:image" content={CreativityBlogThumbnail} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            Creativity Unraveled: Harnessing the Power of the Spontaneous.
          </h1>
            <em className="text-xl">
              (I'll continue to write these 'by hand' until ChatGPT can match my
              wit. Remember, spelling/grammar mistakes are a feature of human
              writing now, not a bug!)
            </em>
          <p className="mb-4">
            How do we be creative in our lives? And what
            does that even mean?
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={CreativityMeme}
            alt="'Aliens' meme, Creativity is a conspriacy theory"
          />
          <h2>Introduction: A Dive into the Nebulous</h2>
          <p className="mb-4">
            Creativity - a treasured buzzword that radiates across conversations
            of politics, academia, war, love, and business alike. It is a
            rightful fascination of many scholars and laypeople alike, as it is
            an intriguing and powerful concept that is often ill-defined. In
            this short blog, we will try to ground this idea of creativity and
            perhaps understand how to "control" a concept that, to many, is
            spontaneous. As always, there are no definite answers here,
            especially as we embark into the abstract. Think of it as more of a
            thought-exercise than any "fact-finding" - something we can all
            benefit from.
          </p>
          <h2>Creativity: Why the fixation?</h2>
          <p className="mb-4">
            Debates around the definition of creativity are as diverse as the
            ideas it generates. Some say it's an innate ability, while others
            believe it's a skill we can develop - some even decide it's only a
            drug-induced mental state in which "magic happens". Let's just
            define it this way (somewhere in the middle of the three);
            Creativity is our ability to come up with new and valuable ideas,
            solutions, or expressions. And by "new," it is more so things that
            feel new or have little existing counterparts. True novelty is a
            discussion in and of itself, but we can keep things vague for our
            sanity here.
          </p>
          <p className="mb-4">
            Creativity is a multidimensional concept, touching our thoughts,
            emotions, and actions across all aspects of our lives. To categorize
            its application better, we can break it into three categories - If
            you can't already tell, I am a believer in the{" "}
            <a href="https://en.wikipedia.org/wiki/Rule_of_three_(writing)">
              “Rule of Three”
            </a>
            :
          </p>
          <ol className="mb-4">
            <li>
              <strong>Personal (individual scale):</strong> This dimension of
              creativity covers the personal aspects of our lives, such as art,
              academia, emotions, and problem-solving. It's where we express our
              inner thoughts and feelings, explore new ideas, and challenge
              ourselves to think outside the box. This is the same kind that
              teachers ask of their students and people ask of themselves.
            </li>
            <li>
              <strong>Interpersonal (small scale): </strong> This is the
              dimension of our relationships and interactions with others - in
              whatever form those may be. It's present in communication, sports,
              social situations, and conflict resolution. When we tap into our
              creative side, we can navigate tricky conversations, find common
              ground, and build stronger connections with those around us. From
              a turkey-day table politics argument to avoiding playing rock,
              paper, scissors with your spouse to decide who takes out the trash
              - creativity comes in handy.
            </li>
            <li>
              <strong>Societal (large scale):</strong> At a 10,000 ft level,
              creativity shapes our society through technological advancements,
              cultural movements, and cross-disciplinary breakthroughs in
              science and culture: think about the invention of the meme - a
              near perfect display of creativity. Creativity (like memes) drives
              innovation, challenges societal norms, and inspires entire
              generations to think and act differently. It is a catalyst for
              change, pushing us to redefine what's possible and imagine new
              horizons - all starting with new meme formats.
            </li>
          </ol>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={ModernArtMeme}
            alt="Modern Art Meme"
          />
          <h2>Cultivating a Brain on Creativity</h2>
          <p className="mb-4">
            Okay, so we could dive into each of the three buckets of creativity
            we outlined above, but there is a lot to unpack there - we can just
            say that they are all important in their own way and move on. It's
            more helpful to discuss strategies on how to drive creativity at
            that first level - after all it's likely the only one we as
            individuals have absolute control over, if any at all.
          </p>
          <p className="mb-4">
            Thanks to neuroscience, we know that creativity isn't just about one
            chemical or "creative" part of the brain. It's more like a group
            effort, with different brain areas (like the default mode network,
            the executive control network, and the salience network) working
            together to make whatever the “magic” is, happen. Likewise, we also
            have no direct control over our neurology, but it's useful to
            mention this if we are discussing creativity as research on it is
            nontrivial. More importantly, embracing neuroplasticity as a real
            concept and a component of creative thinking is a precursor to doing
            anything about it. It can act as an anchor for deciding on how
            exactly one goes about creating more creativity.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={SchoolCreativityMeme}
            alt="School Creativity Meme - restricts students' creativity"
          />
          <h2>What's the Recipe?</h2>
          <p className="mb-4">
            Of course, no definitive answers on this, but here are some
            components of “successful” creative strategies. Some are high-level
            but making these actionable is much easier than trying to access
            your default mode network:
          </p>
          <ul className="mb-4">
            <li>
              <em>Switch it up. </em>Try new activities, such as yoga,
              pickleball, or Thai food, that are outside your usual range (and
              safe).
            </li>
            <li>
              <em>
                {" "}
                <a href="https://hbr.org/2016/01/what-having-a-growth-mindset-actually-means">
                  Growth mindset
                </a>
                .{" "}
              </em>
              Surely your beliefs aren't foolproof. Find times and places to
              challenge even the most fundamental of your beliefs.
            </li>
            <li>
              <em>Be Freudian.</em> Try letting your mind wander. Give yourself
              nothing to do or think about and allow{" "}
              <a href="https://psychcentral.com/health/free-association-therapy">
                free association
              </a>{" "}
              to take over.
            </li>
            <li>
              <em>Do the opposite.</em>{" "}
              <a href="https://www.mindful.org/mindfulness-how-to-do-it/">
                Meditate
              </a>{" "}
              in whatever formation, position, and framing that is helpful to
              you and now try to focus intensely on something - not letting your
              mind wander.
            </li>
            <li>
              <em>Collaborate. </em>Team up with others and explore creativity
              together.
            </li>
            <li>
              <em>Practice, practice, practice. </em>Like any skill, creativity
              requires consistent effort.
            </li>
            <li>
              <em>Create a conducive environment. </em>Buy some plants and LED
              strip lights (BLUE only) for maximum creative results… just
              kidding. But an environment that limits distraction and escapist
              tendencies will be helpful in your pursuit of creativity -
              whatever that means to you.
            </li>
          </ul>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={ShorseImage}
            alt="a shorse, a horse with a shark for a head"
          />
          <h2>Concluding on Creativity</h2>

          <p className="mb-4">
            Creativity as an end of whatever means you try might seem like it
            defeats the purpose of creativity itself - it's a spontaneous thing
            anyway right? Well, maybe not, but pursuing it can't make you any
            less creative so you might as well. After all, creativity is the
            unstoppable force of society and the never ending talking point of
            art and engineering professors alike - so it will probably help you
            with whatever you're going through and need its supernatural powers
            for.
          </p>
          <p className="mb-4">
            If you'd like to talk creativity or anything else - however random -
            with me, find me on{" "}
            <a href="https://www.linkedin.com/in/andrew-rust30/">LinkedIn</a> or
            shoot me an <a href="mailto:andrew@bytesizelearning.org">email</a>.
            Thanks for reading!
          </p>
        </body>
      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default MAY_5_2023;
