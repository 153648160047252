import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";
import ShakespeareMeme from "../../assets/OCTOBER_6_2023Assets/ShakespeareMeme.png";
import MinecraftMeme from "../../assets/OCTOBER_6_2023Assets/MinecraftMeme.png";
import BearGrillsMeme from "../../assets/OCTOBER_6_2023Assets/BearGrillsMeme.png";
import BernieSandersMeme from "../../assets/OCTOBER_6_2023Assets/BernieSandersMeme.png";
import OfficeSpaceMeme from "../../assets/OCTOBER_6_2023Assets/OfficeSpaceMeme.png";

function TEMPLATE() {
  const blogTitle = "Computing, Creating, and Curiosity: The Triad of Technology Education";
  const blogSubtitle = "The foundation of modern creation, just doing it, and the heartbeat of the socratic method - here we attempt to conceptualize the broader issue and pose a solution regarding technology education.";
  const blogAuthor = "Andrew Rust";
  const datePublished = "October 6th, 2023";
  const shareLink = "https://blogs.bytesizelearning.org/posts/triad-of-technology-education";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="STEM Education, Creativity" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            Computing, Creating, and Curiosity: The Triad of Technology Education
          </h1>
          <p className="mb-4">
            Computing technology, with its ubiquity and adoption, excites many
            while also invoking worry in others. Regardless of its ultimate
            trajectory and humankind's landing zone, understanding the
            underlying concepts behind technology and developing the requisite
            skills and practicum for technology have become increasingly
            important with each passing day — and notably, exponentially more
            critical for future generations.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={OfficeSpaceMeme}
            alt="Office Space Meme"
          />
          <p className="mb-4">
            Finding productive, ethical, and intriguing activities within
            technology becomes further and further out of reach as our
            educational system (at least in the US) struggles to keep up with
            the dynamic landscape of the modern era. It's tempting to view
            technology as an isolated entity, possessing its own realm of
            natural laws and atomic mechanics. However, disconnecting it from
            the fabric of our society and human nature navigates a grey area at
            best and, at worst, only serves to distance humans further from
            understanding and utilizing it properly.
          </p>
          <p className="mb-4">
            In an effort to conceptualize the broader issue and pose a solution
            regarding technology education (or the lack thereof), I outline
            three tenets of technology education that permeate generational
            differences and sidestep the specifics of social fads, which often
            claim technology as their victim. I refer to them as the{" "}
            <strong>"three C's"</strong>. Although these ideas overlap
            significantly and numerous representations of this issue exist, this
            is a concise and holistic view of my broader perspective, which I
            find beneficial and digestible to explain.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={ShakespeareMeme}
            alt="Shakespeare Meme"
          />
          <h3>Computing: The Modern Quill</h3>
          <p className="mb-4">
            <em>The foundation of modern creation</em>
          </p>
          <p className="mb-4">
            Just as historical scribes wielded ink and quill to write history,
            modern individuals use micro-processing to shape the future.
            Semantically - <strong>computing</strong> as a term encompasses
            micro-processing hardware, software, and algorithms, which form the
            basis of our future. Altogether it serves not only as the de-facto
            tool for innovation but also as an enhanced outlet for artistic or
            otherwise meaningful expression as the mediums multiply with every
            new technological development. From animation and videography to
            game design and websites, we continually uncover more ways to
            express and communicate ourselves thanks to computing. While this
            may seem inspiring, just as not all scribes became Shakespeare, not
            all modern-day wielders of computing will contribute to expression
            and innovation. Both the conceptual understanding of technology and
            the associated skills are necessary to anthropomorphize our visions
            into a reality. The key lies in our methods for adopting and using
            technology as well as our intentions behind our visions, not the
            potential of computing alone.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={BearGrillsMeme}
            alt="Bear Grills Meme"
          />
          <h3>Creating: The Eternal Flame of Human Spirit</h3>
          <p className="mb-4">
            <em>Just do it</em>
          </p>
          <p className="mb-4">
            Cave paintings & Minecraft, two creations eons apart embody the same
            spirit of creation that is the eternal flame kindling our human
            journey. It's interesting to fathom how ancient humans would
            perceive something like Minecraft, yet the intrinsic and pervasive
            desire to create has undeniably connected our endeavors across
            epochs. The forms, features, and scales of our creations have varied
            immensely, encompassing physical entities like paintings and
            children, as well as conceptual and digital formations, such as
            philosophical ideas and software. Although our visions sprawl across
            diverse domains {" "}
            <strong>music, memes, art, products, apps, events, websites</strong>{" "}
             the essential essence to act and manifest these visions is poetic
            yet vital. While mediums have evolved, the core of creation has
            endured: just as sculptors once envisioned figures within blocks of
            stone, today's digital creators visualize their creations through
            code or software, urging us not to ignore our inherent desire to
            create. It's in action, the pivotal step of{" "}
            <strong>production</strong> rather than <strong>consumption</strong>
            , where the important distinction lies, ensuring we mold our means
            to give life to our visions in whichever way we deem fit and
            attainable.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={MinecraftMeme}
            alt="Minecraft Meme"
          />
          <h3>Curiosity: The Heartbeat of Progress</h3>
          <p className="mb-4">
            <em>Why? But why? Why that? Why?</em>
          </p>
          <p className="mb-4">
            Why do we create in the absence of tangible incentives, and why opt
            to act when inaction might ostensibly be <em>easier</em>? The
            answer, perhaps, resides in curiosity, which not only forms the
            cornerstone of effective education but also fuels modern progress,
            whether driven by the Socratic method or scientific inquiry. This
            intrinsic desire to know, to uncover answers, seemingly doesn't
            require justification in its own right. As educators often observe,
            genuinely curious students self-navigate through learning, reaching
            depths and paces that conventional pedagogical methods rarely
            attain. Thus, cultivating curiosity becomes pivotal, emerging as an
            intrinsic goal within technology education and, indeed, within the
            broader educational landscape. Embarking on a journey towards
            knowledge invariably begins with an acknowledgment of our ignorance,
            and it's curiosity that propels us forward when no other incentive
            beckons.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={BernieSandersMeme}
            alt="Bernie Sanders Meme"
          />
          <p className="mb-4">
            In essence, technology education does not exist as a detached entity
            within a vacuum; rather, it emerges as an organic melding of our
            historical essence and futuristic aspirations, constructed upon the
            sturdy triad of <strong>computing, creating, and curiosity</strong>.
            Are we not all wanderers, animated by curiosity, armed with the
            tools of computing, and inspired by the spirit of creation? Should
            you resonate with this message, consider subscribing to our{" "}
            <a href="https://blogs.bytesizelearning.org/rss.xml">RSS Feed</a>,
            or connect with me via{" "}
            <a href="mailto:andrew@bytesizelearning.org">email</a> or{" "}
            <a href="https://www.linkedin.com/in/andrew-rust30/">LinkedIn</a> -
            your reflections and alternative perspectives on technology
            education and its future are always welcome. Thanks for reading.
          </p>
        </body>
      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default TEMPLATE;
