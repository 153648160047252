import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";
import HackingMeme from "../../assets/JUNE_2_2023Assets/HackingMeme.gif";
import InternetPoliceEverywhere from "../../assets/JUNE_2_2023Assets/InternetPoliceEverywhere.jpg";
import ThinkOfTheChildren from "../../assets/JUNE_2_2023Assets/ThinkOfTheChildren.jpg";
 
function TEMPLATE() {
  const blogTitle = "Cyber Street Smarts: A Proposition for a Driver's License 2.0";
  const blogSubtitle = "Do we need an 'Internet License' for the digital highways and alleyways? - Delve into the discussion around digital literacy, its necessity in our ever-expanding cyber world, and the idea of an Internet License as a safeguard and empowerment tool for users.";
  const blogAuthor = "Andrew Rust";
  const datePublished = "June 2nd, 2023";
  const shareLink = "https://blogs.bytesizelearning.org/posts/exploring-a-digital-literacy-license-in-2023";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="STEM Education, Creativity" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
        {/* <meta property="og:image" content={CreativityBlogThumbnail} /> */}
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
            <em className="text-xl">
              (See if you can find the one sentence generated by ChatGPT, as
              much as I'd hate to admit it - it's improving)
            </em>
          <p className="mb-4">
            What started as a humble(!) tool to quickly share information
            between researchers and military personnel, the internet has become
            the single largest change to society since the Industrial
            Revolution, even dwarfing it to some degree. It has redefined human
            connection, education, commerce, entertainment, politics, and pretty
            much everything. Albeit, this comes at a price. Privacy, changes to
            how we find and view “truth”, new avenues for criminals
            (cybercrime), and much more have all been fallout we struggle with
            today.
          </p>
          <p className="mb-4">
            Riding this wave of digital change the last few decades has been
            exciting although frightening, and with Artifical Intelligence on
            the horizon, this narrative only intensifies. One remedy (although
            limited in scope, but not in significance) would be a proposition
            put forth here: an Internet License. Think of it as a driver's
            license 2.0. No, this is not a Big brother-style surveillance or
            some limitation of our online freedom - rather an effort to increase
            our baseline understanding of technologies that form the internet
            and the inherent risks associated with using it. It's particularly
            important for those new to the digital world, the ones who are yet
            to grasp the inner workings of the internet's technology. And yes,
            that includes the children (and elderly!)
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={ThinkOfTheChildren}
            alt=""
          />
          <p className="mb-4">
            The point of this would not be to evoke fear, but caution. The
            internet has lots of wonders to offer, but they should be explored
            and used with our well-being in mind.
          </p>
          <h3>
            What and Why?: Defining a Footprint on the Digital Superhighway
          </h3>
          <p className="mb-4">
            What a time to be alive: We are but a swipe, tap, or keyboard stroke
            away from finding a new date, ordering a pizza, or discovering that
            strawberries aren't actually berries (blasphemy!). It may seem like
            magic, but the internet is a product of human ingenuity and like all
            else of humanity's creations it comes at a cost. In our internet
            surfings, weleave behind a trail of invaluable data and information
            - our 'digital footprint'. Some of which is innocuous - like
            targeted advertisements, others not so much (hackers launching a
            DDoS attack to your IP address.
          </p>
          <p className="mb-4">
            Now consider the “digital footprint” left behind by those most
            vulnerable - namely the smartphone-savvy toddlers as well as those
            whose memories date back to black-and-white televisions. The size of
            their “digital footprint” is vast - one that could make Bigfoot
            self-conscious (had to throw in a Bigfoot joke).There is valid cause
            for concern and a compelling argument to be made in favor of an
            'internet use license'. Much like how we ensure that teenagers have
            a certain level of knowledge and responsibility before they hit the
            road, we should likewise ensure that internet users navigate the
            binary superhighway responsibly and safely.
          </p>
          <p className="mb-4">
            Keeping the car analogy in play, let's be realistic: we can't expect
            every internet user to be a network engineer (the internet's
            “mechanic”). However, it is essential that users understand some
            basics, akin to knowing how to use GPS, fastening the seatbelt, and
            recognizing when to slam the brakes.
          </p>
          <h3>What Would a Digital Driver's License Look Like?</h3>
          <p className="mb-4">
            First things first: No, an internet cop can't take away your license
            for sharing a terrible meme in a family group chat. And it likely
            wouldn't be required in any sense, rather an ubiquitously adopted
            framework of knowledge that everyone should have before becoming a
            hitting the open web. So what might this 'license' entail?
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={InternetPoliceEverywhere}
            alt=""
          />
          <p className="mb-4">
            To start, a 'licensed operator' should understand the privacy
            settings that underpin operating systems, browsers, websites, and
            apps. Along those lines, they should be able to sniff out scams -
            from phishing emails to weird notifications and any kind of sketchy
            online behavior.
          </p>
          <p className="mb-4">
            And let's not forget about cryptography, a key player in the
            security game. Knowing how to encrypt and decrypt information can
            make all the difference when it comes to safeguarding your data.
            This knowledge cannot and should not be restricted to security
            professionals - it is too vital of a tool for privacy. Familiarity
            with a password manager (KeePassXC for example) goes a long way too.
          </p>
          <p className="mb-4">
            The license would also need to cover network security basics:
            getting to grips with HTTP, IP addresses, URLs, and the like.
            Lastly, wiping or clean installing an operating system after
            discovering malware should be within the scope of this license too.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={HackingMeme}
            alt=""
          />
          <p className="mb-4">
            Again, this isn't about restricting freedoms or barring people from
            using the internet. Instead, it's about ensuring "caveat viator"
            (let the traveler beware) is a fair warning - letting users
            reasonably assume online responsibility armed with the knowledge of
            how things work. It is especially important for future generations
            to not be further influenced by negative externalities of the
            internet but instead be empowered in their online journeys.
          </p>
          <h3>
            Education, Not Avoidance: The Highway Within The Digital Metropolis
          </h3>
          <p className="mb-4">
            In embarking on this discussion, the objective has always been to
            improve understanding and empower internet users with the skills
            they need to navigate the digital world safely. This proposition of
            an Internet License is not a call to erect digital barriers but
            rather to provide users with a set of keys, freeing them from the
            confines of ignorance and uncertainty.
          </p>
          <p className="mb-4">
            The internet, with its infinite opportunities, is like a sprawling,
            ever-expanding metropolis that outshines even the grandest of our
            physical cities like New York or Tokyo. This cyber city, however, is
            not without its shadowy corners and winding alleyways. It's crucial
            that we, its digital denizens (new WOTD), learn the lay of the land,
            becoming fluent in its language and customs, and being streetwise to
            its potential hazards. Not because there's something inherently
            wrong with the city itself, but because it's a complex,
            ever-evolving landscape.
          </p>
          <p className="mb-4">
            With the endless opportunity provided, endless risk is also
            available. But cheers to the most impressive tool mankind has built
            to date. Maybe a license moves us in the right direction to be
            informed citizens of a democratic, open internet. But, second
            opinions are always appreciated. Find me on LinkedIn or shoot me an
            email. Thanks for reading!
          </p>
        </body>
      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default TEMPLATE;
